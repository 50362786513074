@import '~antd/dist/antd.css';

#loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  font-size: 20px;
}
#loading .ant-spin-dot-item {
  background-color: #1669FF;
}

.news-info-content {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}
.news-info-box {
  background: #fff;
  border-radius: 8px;
}
.info-title {
  font-size: 18px;
  line-height: 25px;
  color: #26282C;
  letter-spacing: 0.015em;
  word-break: break-all;
}
.publisher {
  display: inline-block;
  margin-right: 8px;
  background: #E1FDFF;
  border-radius: 2px;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #94A1B8;
  padding: 0 4px;
}
.publish-time {
  flex-shrink: 0;
  font-size: 12px;
  line-height: 18px;
  color: rgba(38, 40, 44, 0.45);
}
.list-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;
}
.list-info div {
  margin-right: 10px;
}
.list-info .info-text {
  font-size: 14px;
  line-height: 22px;
  color: rgba(38, 40, 44, 0.65);
}
.info-body {
  margin-top: 16px;
}
.info-body .news-img {
  max-width: 100%;
  max-height: 407px;
  width: 100%;
  height: 100%;
  margin: 16px auto;
  display: block;
  object-fit: cover;
}
.info-body .info-content {
  word-break: break-all;
}
